<template>
  <div class="flex flex-col lg:flex-row space-y-2">
    <div class="w-full lg:w-1/3">
      <p>Verwalten Sie den Zugang der Benutzer und die dazugehörigen Berechtigungen</p>
    </div>
    <div class="w-full lg:w-2/3">
      <router-link to="/settings/invite">
        <div class="w-full rounded px-4 py-8 bg-white border shadow-sm flex justify-between mb-1">
          <div class="flex space-x-2 items-center">
            <div class="text-4xl text-blue-dark">
              <i class="fa fa-user"></i>
            </div>
            <p>Benutzer verwalten</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </router-link>
      <router-link to="/settings/groups-and-permissions">
        <div class="w-full rounded px-4 py-8 bg-white border shadow-sm flex justify-between">
          <div class="flex space-x-2 items-center">
            <div class="text-4xl text-blue-dark">
              <i class="fa fa-users"></i>
            </div>
            <p>Gruppe &amp; Berechtigungen verwalten</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-and-groups',
  data() {
    return {
      breadcrumbs: {
        title: [
          { link: '/settings', crumb: 'Allgemeine Einstellungen' },
          { crumb: 'Benutzer & Gruppenverwaltung' },
        ],
        pageTitle: 'Benutzer & Gruppenverwaltung',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
